import { useEffect, useMemo, useState } from 'react'
import { useList, useMap } from 'react-use'
import { type ListActions } from 'react-use/lib/useList'
import { type Actions as FilterActions } from 'react-use/lib/useMap'

import {
  type UsePaginatedRequestClientConfig,
  type UsePaginatedRequestResponse,
  type UsePaginatedRequestSWRConfig,
  usePaginatedRequest
} from '../service/use-paginated-request'
import { type UseRequestStatus } from '../service/use-request'

type Filter = Record<string, any>

export interface UseInfiniteRequestResponse<Data, Error>
  extends Omit<UsePaginatedRequestResponse<Data[], Error>, 'data' | 'mutate'> {
  list?: Data[]
  filter?: Record<string, string>
  filterActions?: FilterActions<any>
  listActions?: ListActions<any>
}

export type UseInfiniteRequestClientConfig = UsePaginatedRequestClientConfig
export type UseInfiniteRequestSWRConfig<Data, Error> = UsePaginatedRequestSWRConfig<Data, Error>

export type PaginatedFetcher = <Data, Error>(...args: any) => Omit<UsePaginatedRequestResponse<Data, Error>, 'mutate'>

export function statusProxy(
  status: UseRequestStatus,
  list: unknown[],
  error?: unknown,
  isValidating?: boolean,
  data?: unknown[]
): UseRequestStatus {
  if (
    (list.length && status === 'loading') ||
    (list.length && status === 'idle') ||
    (!data && status === 'resolved') ||
    (data?.length && !list.length && status === 'resolved')
  )
    return 'refreshing'

  if (error) return 'rejected'

  if (isValidating) return 'loading'

  return status
}

export function useInfiniteRequest<Data, Error>(
  requestConfig: UseInfiniteRequestClientConfig,
  swrConfig?: UseInfiniteRequestSWRConfig<Data, Error>,
  paginatedFetcher: PaginatedFetcher = usePaginatedRequest
): UseInfiniteRequestResponse<Data, Error> {
  const [keyControl, setKeyControl] = useState<string>()
  const [filter, filterActions] = useMap<Filter>()
  const [list, listActions] = useList<Data>()

  const requestConfigWithParamsAndFilter = useMemo(
    () => ({ url: requestConfig.url, params: { ...requestConfig.params, ...filter } }),
    [requestConfig, filter]
  )

  const {
    data,
    key,
    pagination,
    status: responseStatus,
    error,
    isValidating,
    ...restFetcherResponse
  } = paginatedFetcher<Data, Error>(requestConfigWithParamsAndFilter, swrConfig)

  const status = useMemo(
    () => statusProxy(responseStatus, list, error, isValidating, data),
    [responseStatus, list, error, isValidating, data]
  )

  useEffect(
    function reset() {
      listActions.reset()
      pagination.reset()
    },
    [filter, requestConfig.url]
  )

  useEffect(
    function updateEntries() {
      if (data && key !== keyControl) {
        listActions.push(...data)
        setKeyControl(key)
      }
    },
    [data]
  )

  return {
    ...restFetcherResponse,
    isValidating,
    status,
    pagination,
    list,
    listActions,
    filter,
    key,
    filterActions
  }
}

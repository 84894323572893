import type React from 'react'

import { Heimdall } from '../core/heimdall'
import { ChatFlex } from '../infra/chat/components'
import { CustomChatShell } from '../infra/custom-chat/components/custom-chat-shell'
import { type Subject } from '../infra/deus-ex-machina/types'
import { withInjectedProps } from '../infra/page-enhancers/compositional/with-injected-props'
import { clientSideOnly } from '../lib/client-side-only'

export const Chat: React.FC<{ subject: Subject }> = ({ subject, ...restProps }) => {
  const hasCustomChat = clientSideOnly(() => Heimdall.pass(['web_custom_chat']), false)

  const withChatProps = withInjectedProps({ subject })

  return withChatProps(hasCustomChat ? CustomChatShell : ChatFlex)({ ...restProps })
}

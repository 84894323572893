export const toDataURL = (
  src: string,
  callback: (result: string | ArrayBuffer | null) => void,
  xhttp = new XMLHttpRequest(),
  fileReader = new FileReader()
) => {
  xhttp.onload = () => {
    fileReader.onloadend = () => {
      callback(fileReader.result)
    }

    fileReader.readAsDataURL(xhttp.response)
  }

  xhttp.responseType = 'blob'
  xhttp.open('GET', src, true)
  xhttp.send()
}

import { type Message, type SendMediaOptions, Client } from '@twilio/conversations'

import { logger } from '../../monitoring/logger'
import { type CustomMessage } from '../types'

export class ChatManager {
  private client: Client
  private chatIdentity: string
  private channelSId: string

  constructor(token: string, channelSId: string, chatIdentity: string) {
    this.client = new Client(token)
    this.channelSId = channelSId
    this.chatIdentity = chatIdentity
  }

  private async _getConversation() {
    return await this.client.getConversationBySid(this.channelSId)
  }

  private async parseMessage(message: Message): Promise<CustomMessage> {
    let user: string | null = null

    try {
      user = message.author ? (await this.client.getUser(message.author))?.friendlyName || null : null
    } catch (error) {
      if (message.author) {
        user = message.author
      } else {
        logger(error)
      }
    }

    const mediaUrl =
      message.attachedMedia && message.attachedMedia[0] ? await message.attachedMedia[0].getContentTemporaryUrl() : null

    return {
      content: message.body || '',
      timestamp: message.dateCreated || new Date(),
      sender: user || '',
      me: message.author === this.chatIdentity,
      id: message.sid,
      mediaUrl: mediaUrl
    }
  }

  public async getMessages() {
    const conversation = await this._getConversation()

    const messages = await conversation.getMessages()

    return Promise.all(messages.items.map(async message => await this.parseMessage(message)))
  }

  public async sendMessage(message: string | SendMediaOptions) {
    const conversation = await this._getConversation()

    await conversation.sendMessage(message)
  }

  public onNewMessage(callback: (newMessage: CustomMessage) => void) {
    this.client.on('messageAdded', async message => {
      callback(await this.parseMessage(message))
    })
  }
}

import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'

import { Flex, Input } from '../index'

import { type InputProps } from './interface'

export const StyledInput = styled(Input)<InputProps>`
  padding: ${theme('space.medium')};
  border-color: ${ifProp(
    'hasWarning',
    theme('colors.alertDarkOrange'),
    ifProp('hasError', theme('colors.danger'), theme('colors.input.border'))
  )} !important;
  color: ${ifProp('hasError', theme('colors.placeholder.error'), theme('colors.label.inactive'))};

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    background-color: ${ifProp('placeholder', theme('colors.muted'))};
  }

  [type='color'] {
    appearance: none;
    border: none;
    width: 32px;
    height: 32px;
  }
`

export const Adornment = styled(Flex)<InputProps>`
  border-width: ${ifProp('borderless', '0', '1px')};
  border-color: ${ifProp('hasError', theme('colors.danger'), theme('colors.input.border'))} !important;
  cursor: ${ifProp('onClick', 'pointer')};

  &:first-child {
    padding-left: ${ifProp('lessAdormentPadding', '0.25rem', theme('space.medium'))};
    border: ${ifProp('adornmentBorderless', 'none !important')};
    align-items: center;
  }

  &:last-child {
    padding-right: ${theme('space.medium')};
  }
`

export const InputContainer = styled(Flex)`
  ${StyledInput},
  ${Adornment} {
    border-style: solid;
    border-color: ${ifProp('hasError', theme('colors.danger'), theme('colors.input.border'))};
    border-left: none;
    border-right: none;
    border-radius: 0;

    &:first-child {
      border-left: 1px solid ${ifProp('error', theme('colors.danger'), theme('colors.input.border'))};
      border-right: 0;
      border-radius: ${theme('radii.default')} 0 0 ${theme('radii.default')};
    }

    &:last-child {
      border-left: 0;
      border-right: 1px solid ${ifProp('error', theme('colors.danger'), theme('colors.input.border'))};
      border-radius: 0 ${theme('radii.default')} ${theme('radii.default')} 0;
    }

    &:only-child {
      border-left: 1px solid ${ifProp('error', theme('colors.danger'), theme('colors.input.border'))};
      border-right: 1px solid ${ifProp('error', theme('colors.danger'), theme('colors.input.border'))};
      border-radius: ${theme('radii.default')};
    }
  }
`

import styled, { keyframes } from 'styled-components'

import { Flex } from '../index'

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const WrapperLoader = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > svg {
    animation: ${rotate} 0.6s linear;
    animation-iteration-count: infinite;
    animation-delay: -300ms;
  }
`

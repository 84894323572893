import styled, { css } from 'styled-components'
import { theme } from 'styled-tools'

export const CustomChatWrapper = styled.div`
  position: fixed;
  background: white;
  height: min(85vh, 43.75rem);
  min-width: min(360px, 100%);
  max-width: 40rem;
  width: 22.5rem;
  overflow: auto;
  bottom: 4.625rem;
  right: 1.25rem;
  border-radius: calc(0.44rem * 4);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
  z-index: ${theme('zIndices.topMenu')};
`

export const CustomButtonWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  width: 6rem;
  height: 2.75rem;
  bottom: 1rem;
  right: 1.5rem;
  display: flex;
  z-index: ${theme('zIndices.topMenu')};

  ${({ isOpen }) =>
    isOpen &&
    css`
      width: 2.75rem;
      height: 2.75rem;
      bottom: 1rem;
      right: 1.5rem;
      box-shadow: 1px 1px 20px rgba(46, 55, 66, 0.05);
    `}
`

import { useState } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { Heading, Text } from '@dlpco/ginga-stone'

import { Box } from '~/domains/platform/design-system'
import { For } from '~/domains/platform/lib/utilities-components'

import { type ChatUser } from '../types'

import { CustomChatClientHelp } from './custom-chat-client-help'
import { CustomChatDepartmentCard } from './custom-chat-department-card'

export function CustomChooseClient(props: { chatUser: ChatUser }) {
  const { chatUser } = props

  const [client, hasClient] = useState(false)

  const clientOptions = [
    { title: 'Já sou cliente', action: () => hasClient(true) },
    {
      title: 'Quero ser cliente',
      action: () => {}
    }
  ]

  if (client) return <CustomChatClientHelp chatUser={chatUser} />

  return (
    <Box padding="1.25rem">
      <Stack space="1rem">
        <Heading weight="bold">Vamos começar seu atendimento?</Heading>
        <Text>Antes de começarmos precisamos que você escolha uma das opções abaixo.</Text>

        <For
          of={clientOptions}
          render={item => <CustomChatDepartmentCard title={item.title} action={item.action} key={item.title} />}
        />
      </Stack>
    </Box>
  )
}

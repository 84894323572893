import { useForm } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import { Stack } from '@dlpco/fluid-layout'
import { Button, Heading } from '@dlpco/ginga-stone'

import { Box } from '~/domains/platform/design-system'
import { Input } from '~/domains/platform/design-system/input/input'
import { emailValidator, masks, telValidator, unmask } from '~/lib/helpers'

import { type PublicUserTwilioProps } from '../../chat/components/twilio/helpers'

export function CustomPublicChat({ onSuccess }: { onSuccess(user: PublicUserTwilioProps): void }) {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors }
  } = useForm<{ name: string; email: string; phone: string }>()

  const onSubmit = handleSubmit(({ name, phone, email }) => {
    const phoneWithoutMask = unmask(phone)

    if (!telValidator(phoneWithoutMask.slice(2))) {
      setError('phone', {
        type: 'invalid_phone',
        message: 'Celular Inválido!'
      })

      return
    }

    if (!emailValidator(email)) {
      setError('email', {
        type: 'invalid_email',
        message: 'Email Inválido!'
      })

      return
    }

    onSuccess({ phoneNumber: phoneWithoutMask, displayName: name, email: email })
  })

  const phoneFormRegistration = register('phone', { required: 'Campo obrigatório' })

  return (
    <form onSubmit={onSubmit}>
      <Box padding="1.25rem">
        <Stack space="1rem">
          <Heading weight="bold">Preencha seus dados</Heading>
          <Input
            label="Nome Completo"
            {...register('name', { required: 'Campo obrigatório' })}
            id="name"
            placeholder="Insira seu nome completo"
            error={errors.name?.message}
          />
          <MaskedInput
            guide={false}
            mask={masks.telephone}
            render={(ref, maskedProps) => (
              <Input
                label="Celular"
                id="phone"
                placeholder={'Ex: (00) 99999-9999'}
                {...maskedProps}
                {...phoneFormRegistration}
                ref={input => {
                  if (input) {
                    ref(input)
                    phoneFormRegistration.ref(input)
                  }
                }}
                onChange={event => {
                  maskedProps.onChange(event)
                  phoneFormRegistration.onChange(event)
                }}
                onBlur={event => {
                  maskedProps.onBlur(event)
                  phoneFormRegistration.onBlur(event)
                }}
                error={errors.phone?.message}
              />
            )}
          />
          <Input
            label="E-mail"
            {...register('email', { required: 'Campo obrigatório' })}
            id="email"
            placeholder="user@stone.com.br"
            error={errors.email?.message}
          />
          <Button size="large" type="submit" data-testid="submit">
            Avançar
          </Button>
        </Stack>
      </Box>
    </form>
  )
}

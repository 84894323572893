import { type NextPage } from 'next'

import { Chat } from '~/domains/platform/ports/chat'

import { type WithDeusExMachinaProps } from '../../deus-ex-machina/types'

export function withChat(Page: NextPage<WithDeusExMachinaProps>) {
  function WrappedPage({ subject, ...rest }: WithDeusExMachinaProps) {
    return (
      <>
        <Page subject={subject} {...rest} />
        <Chat subject={subject} />
      </>
    )
  }

  WrappedPage.displayName = `withChat(${Page.displayName})`

  return WrappedPage
}

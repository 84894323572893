import { type SetOptional } from 'type-fest'

import { PORTS__TWILIO__CUSTOM_API_ENDPOINT } from '~/lib/constants/envs'
import { useQueryRequest } from '~/ui/hooks/utils'

import packageInfo from '../../../../../../package.json'
import { simpleHttpClient } from '../../http/simple-http-client'
import { type Department } from '../entities'
import { type ChatUser } from '../types'

interface CreateChannelParams {
  chatUser: ChatUser
  department: Department
  chatIdentity: string
}

const TWILIO_CUSTOM_URL = PORTS__TWILIO__CUSTOM_API_ENDPOINT()

const createChannel = async (params: CreateChannelParams) => {
  const [firstName] = params.chatUser.name?.split(' ') || ['']

  const {
    data: { channelSid }
  } = await simpleHttpClient<{ channelSid: string }>({
    method: 'POST',
    baseURL: TWILIO_CUSTOM_URL,
    url: '/create-flex-channel',
    data: {
      identity: params.chatIdentity,
      chatUserFriendlyName: params.chatUser.name,
      chatFriendlyName: firstName,
      preEngagementData: {
        name: params.chatUser.name,
        department: params.department.value || '',
        email: params.chatUser.email,
        phone: params.chatUser.phone,
        isLoggedIn: params.chatUser.loggedIn,
        application: `Stone ${packageInfo.version}`,
        operationalSystem: 'Web'
      }
    }
  })

  return channelSid
}

export const useCreateChannel = (params: SetOptional<CreateChannelParams, 'department'>) => {
  return useQueryRequest<string, unknown, true>(
    ['useChatToken', ...Object.values(params)],
    () => createChannel({ ...(params as CreateChannelParams) }),
    {
      enabled: Boolean(params.department)
    }
  )
}

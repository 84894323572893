import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { DateTime, IconBadge, IconButton, Text } from '@dlpco/ginga-stone'
import imageCompression from 'browser-image-compression'
import styled, { css } from 'styled-components'

import { Box, Flex } from '~/domains/platform/design-system'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'

import { type Department } from '../entities'
import { ChatManager } from '../helpers/chat-manager'
import { type ChatUser, type CustomMessage } from '../types'

import { CustomChatImagePreview } from './custom-chat-image-preview'

interface MessagePayload {
  content: string | File
  contentType: 'text' | 'image/jpeg' | 'image/png'
}

const CustomChatInput = styled.input`
  background: ${props => props.theme.colors.mediumGray4};
  border: none;
  border-radius: 50rem;
  height: 2.25rem;
  padding: 0 1rem;
  flex: 1;
`

const MessageWrapper = styled(Flex)<{ me: boolean }>`
  flex-direction: column;
  max-width: 16rem;
  padding: 5px 0.5rem;
  border-radius: 10px;
  background: ${props => props.theme.colors.mediumGray4};

  ${props =>
    props.me &&
    css`
      margin-left: auto;
      background: ${props => props.theme.colors.primary};
    `}
`

const MessageListWrapper = styled(Flex)`
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  flex: 1;
  margin-top: auto;
  padding: 1rem;
  overflow-y: auto;
`

const Message = ({ content, sender, timestamp, me, mediaUrl }: CustomMessage) => {
  return (
    <Flex gap=".5rem">
      <If condition={!me}>
        <Box>
          <IconBadge style={{ width: '2rem', aspectRatio: '1/1' }} icon="avatar-outline" />
        </Box>
      </If>
      <MessageWrapper me={me}>
        <Flex alignItems="center" gap="1rem" justifyContent="space-between">
          <Text color={me ? 'onColorHigh' : 'neutral'} weight="bold" size="xSmall" style={{ gridArea: 'sender' }}>
            {sender}
          </Text>
          <Text color={me ? 'onColorHigh' : 'neutral'} size="xSmall" style={{ gridArea: 'timestamp' }}>
            <DateTime date={timestamp} preset="time" />
          </Text>
        </Flex>

        <Choose>
          <Choose.When condition={Boolean(mediaUrl)}>
            <Flex justifyContent="center" pt=".2rem">
              <CustomChatImagePreview url={mediaUrl as string} size="large" />
            </Flex>
          </Choose.When>
          <Choose.Otherwise>
            <Text color={me ? 'onColorHigh' : 'neutral'} size="small" style={{ gridArea: 'content' }}>
              {content}
            </Text>
          </Choose.Otherwise>
        </Choose>
      </MessageWrapper>
    </Flex>
  )
}

const CustomChatMessages = forwardRef<HTMLDivElement, { messages: CustomMessage[] }>(({ messages }, ref) => {
  return (
    <MessageListWrapper ref={ref}>
      <For of={messages} render={message => <Message key={message.id} {...message} />} />
    </MessageListWrapper>
  )
})

const CustomChatMainEntry = ({
  onMessageCreation
}: {
  onMessageCreation: (messagePayload: MessagePayload) => Promise<void>
}) => {
  const [sendingMessage, setSendingMessage] = useState<boolean>(false)
  const [uploadedImage, setUploadedImage] = useState<File>()
  const [imageType, setImageType] = useState<'image/jpeg' | 'image/png'>('image/png')
  const { register, handleSubmit, reset } = useForm<MessagePayload>()

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]

    if (file) {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 10
      })

      setUploadedImage(compressedFile)
      setImageType(compressedFile.type as 'image/jpeg' | 'image/png')
    }
  }, [])

  const { getInputProps, open } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png'
  })

  const onSubmit = handleSubmit(async values => {
    setSendingMessage(true)

    if (values.content) {
      await onMessageCreation({ content: values.content, contentType: 'text' })
    }

    if (uploadedImage) {
      await onMessageCreation({ content: uploadedImage, contentType: imageType })
      setUploadedImage(undefined)
    }

    setSendingMessage(false)
    reset()
  })

  return (
    <>
      <If condition={Boolean(uploadedImage)}>
        <Box ml="1rem">
          <CustomChatImagePreview url={uploadedImage as File} onClose={() => setUploadedImage(undefined)} />
        </Box>
      </If>
      <Flex
        as="form"
        aria-label="Mensagens"
        gap=".5rem"
        alignItems="center"
        justifyContent="space-between"
        p="1rem"
        onSubmit={onSubmit}
      >
        <IconButton
          icon="file-image-outline"
          shape="pill"
          size="small"
          color="neutral"
          type="button"
          onClick={open}
          disabled={sendingMessage}
        />
        <input
          {...getInputProps({
            style: { opacity: 0, display: 'none' }
          })}
        />
        <CustomChatInput placeholder="Digite sua mensagem" {...register('content')} disabled={sendingMessage} />
        <IconButton icon="paper-plane-solid" shape="pill" size="small" type="submit" disabled={sendingMessage} />
      </Flex>
    </>
  )
}

export const CustomChatEntries = ({
  chatToken,
  chatUser,
  channelSId,
  chatIdentity,
  department
}: {
  chatUser: ChatUser
  chatToken: string
  channelSId: string
  chatIdentity: string
  department: Department
}) => {
  const chatManager = new ChatManager(chatToken, channelSId, chatIdentity)

  const [messages, setMessages] = useState<CustomMessage[]>([])

  const messagesWrapperRef = useRef<HTMLDivElement>(null)

  const handleSendMessage = async ({ content, contentType }: MessagePayload) => {
    if (contentType === 'text') {
      await chatManager.sendMessage(content as string)
      return
    }

    await chatManager.sendMessage({ media: content, contentType })
  }

  useEffect(() => {
    chatManager.onNewMessage(newMessage => {
      setMessages(oldMessages => [...oldMessages, newMessage])

      setTimeout(() => {
        if (messagesWrapperRef.current) {
          const height = messagesWrapperRef.current.scrollHeight
          messagesWrapperRef.current.scrollTo({ left: 0, top: height, behavior: 'smooth' })
        }
      }, 20)
    })

    async function sendInitialMessage() {
      const currentMessages = await chatManager.getMessages()

      if (!currentMessages.length) {
        chatManager.sendMessage(department.initialMessage.replace('{FirstName}', chatUser.name))
      }
    }

    sendInitialMessage()
  }, [])

  return (
    <>
      <Choose>
        <Choose.When condition={Boolean(messages.length)}>
          <CustomChatMessages messages={messages} ref={messagesWrapperRef} />
        </Choose.When>
        <Choose.Otherwise>
          <Box height="100%" position="relative">
            <Loader />
          </Box>
        </Choose.Otherwise>
      </Choose>
      <CustomChatMainEntry onMessageCreation={handleSendMessage} />
    </>
  )
}

import { PORTS__TWILIO__CUSTOM_API_ENDPOINT } from '~/lib/constants/envs'
import { useQueryRequest } from '~/ui/hooks/utils'

import { simpleHttpClient } from '../../http/simple-http-client'
import { type Department } from '../entities'

const TWILIO_CUSTOM_URL = PORTS__TWILIO__CUSTOM_API_ENDPOINT()

async function getDepartmentsList() {
  const { data } = await simpleHttpClient<Department[]>({ baseURL: TWILIO_CUSTOM_URL, url: '/teams' })

  return data
}

export const useDepartments = () => {
  return useQueryRequest<Department[], unknown, true>(['useDepartments'], getDepartmentsList)
}

import { Cluster } from '@dlpco/fluid-layout'
import { Icon, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Box } from '~/domains/platform/design-system'

export const DepartmentCard = styled.article`
  border: 2px solid ${props => props.theme.colors.mediumGray4};
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  &:hover {
    border: 2px solid ${props => props.theme.colors.stoneGreen};
  }

  &:active,
  &:focus {
    border: 2px solid ${props => props.theme.colors.primary};
  }
`

type CustomChatDepartmentCardProps = {
  title: string
  action: () => void
}

export const CustomChatDepartmentCard = (props: CustomChatDepartmentCardProps) => {
  const { title, action } = props
  return (
    <DepartmentCard onClick={action}>
      <Cluster space="1rem" align="center" noWrap>
        <Box>
          <Text weight="bold" style={{ maxWidth: '30ch' }}>
            {title}
          </Text>
          <Icon use="chevron-right-outline" size="small" />
        </Box>
      </Cluster>
    </DepartmentCard>
  )
}

import { useEffect, useState } from 'react'
import { IconBadge } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { toDataURL } from '~/domains/platform/core/to-data-url/to-data-url'
import { Box } from '~/domains/platform/design-system'
import { If } from '~/domains/platform/lib/utilities-components'

const ImageWrapper = styled(Box)`
  box-shadow: ${props => props.theme.shadows.tiny};
  border-radius: ${props => props.theme.radii.default};
  width: fit-content;
  height: fit-content;
  position: relative;
`

const StyledImage = styled.img<{ size?: 'small' | 'large' }>`
  width: ${props => (props.size === 'large' ? '12rem' : '6rem')};
  aspect-ratio: 1/1;
  border-radius: ${props => props.theme.radii.default};
`

const CloseButton = styled(IconBadge)`
  position: absolute;
  z-index: ${props => props.theme.zIndices.overlay};
  right: -0.5rem;
  top: -0.5rem;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`

export const CustomChatImagePreview = ({
  url,
  onClose,
  size
}: {
  url: string | File
  onClose?: () => void
  size?: 'small' | 'large'
}) => {
  const [src, setSrc] = useState(typeof url === 'string' ? url : undefined)

  useEffect(() => {
    if (url instanceof Blob) {
      toDataURL(URL.createObjectURL(url), imageBase64 => {
        if (typeof imageBase64 === 'string') {
          setSrc(imageBase64)
        }
      })
    }
  }, [url])

  return (
    <ImageWrapper>
      <If condition={Boolean(onClose)}>
        <CloseButton role="button" icon="close-outline" variant="solid" onClick={onClose} />
      </If>
      <If condition={Boolean(src)}>
        <StyledImage src={src as string} size={size} />
      </If>
    </ImageWrapper>
  )
}

import { useState } from 'react'
import { useToggle } from 'react-use'
import { Button, Heading, IconButton } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Choose, If } from '~/domains/platform/lib/utilities-components'

import { type PublicUserTwilioProps } from '../../chat/components/twilio/helpers'
import { type Subject } from '../../deus-ex-machina/types'
import { chatUserFromSubject } from '../helpers/chat-user-from-subject'
import { getChatIdentity } from '../helpers/get-chat-identity'
import { type ChatUser } from '../types'

import { CustomChooseClient } from './custom-chat-choose-client'
import { CustomButtonWrapper, CustomChatWrapper } from './custom-chat-wrapper'
import { CustomPublicChat } from './custom-public-chat'

const CustomChatHeader = styled.div`
  background: ${props => props.theme.colors.primary};
  padding: 0.6rem;
`

export const CustomChatShell = ({ subject }: { subject?: Subject }) => {
  const [chatUser, setChatUser] = useState<ChatUser | undefined>(subject ? chatUserFromSubject(subject) : undefined)
  const [open, toggleOpen] = useToggle(false)

  const chatIdentity = getChatIdentity()

  const onSuccess = ({ email, displayName, phoneNumber }: PublicUserTwilioProps) => {
    setChatUser({
      email,
      name: displayName,
      phone: phoneNumber || '',
      loggedIn: false
    })
  }

  return (
    <>
      <If condition={open}>
        <CustomChatWrapper>
          <CustomChatHeader>
            <Heading color="onColorHigh" size="medium" weight="bold" style={{ textAlign: 'center' }}>
              Vamos te Ajudar :)
            </Heading>
          </CustomChatHeader>
          <Choose>
            <Choose.When condition={Boolean(chatUser && chatIdentity)}>
              <CustomChooseClient chatUser={chatUser as ChatUser} />
            </Choose.When>
            <Choose.Otherwise>
              <CustomPublicChat onSuccess={onSuccess} />
            </Choose.Otherwise>
          </Choose>
        </CustomChatWrapper>
      </If>
      <CustomButtonWrapper isOpen={open}>
        <Choose>
          <Choose.When condition={open}>
            <IconButton
              onClick={toggleOpen}
              aria-label="Fechar Chat"
              icon="chevron-down-outline"
              color="neutral"
              shape="pill"
            />
          </Choose.When>
          <Choose.Otherwise>
            <Button onClick={toggleOpen} icon="chat-solid" shape="pill">
              Ajuda
            </Button>
          </Choose.Otherwise>
        </Choose>
      </CustomButtonWrapper>
    </>
  )
}

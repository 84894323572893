/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react'
import { useMap } from 'react-use'

import { type PaginatedResponse } from '~/domains/platform/infra/http/paginated-client'
import { type Cursor } from '~/lib/types'

import {
  type UseRequestClientConfig,
  type UseRequestResponse,
  type UseRequestSWRConfig,
  useRequest
} from '../service/use-request'

export interface UsePaginatedRequestResponse<Data, Error> extends Omit<UseRequestResponse<Data[], Error>, 'mutate'> {
  pagination: {
    reset: () => void
    forward: () => void
    backwards: () => void
    hasBefore: boolean
    hasAfter: boolean
  }
}

export type UsePaginatedRequestSWRConfig<Data, Error> = UseRequestSWRConfig<PaginatedResponse<Data>, Error>
export type UsePaginatedRequestClientConfig = UseRequestClientConfig

export function usePaginatedRequest<Data, Error>(
  requestConfig: UsePaginatedRequestClientConfig,
  swrConfig: UsePaginatedRequestSWRConfig<Data, Error> = {},
  limit = 100
): UsePaginatedRequestResponse<Data, Error> {
  const initialCursor = { limit }
  const [cursor, cursorActions] = useMap<Cursor>(initialCursor)

  const requestConfigWithParamsAndCursor = useMemo(
    () => ({ ...requestConfig, params: { ...cursor, ...requestConfig.params } }),
    [requestConfig.params, requestConfig.url, cursor]
  )

  const { data: response, ...restSWR } = useRequest<PaginatedResponse<Data>, Error>(
    requestConfigWithParamsAndCursor,
    swrConfig
  )

  function paginationFactory() {
    function forward() {
      cursorActions.set('after', response?.cursor?.after)
    }

    function backwards() {
      cursorActions.set('before', response?.cursor?.before)
    }

    return {
      reset: cursorActions.reset,
      backwards,
      forward,
      hasAfter: Boolean(response?.cursor?.after),
      hasBefore: Boolean(response?.cursor?.before)
    }
  }

  const pagination = useMemo(paginationFactory, [response?.cursor.after, response?.cursor.before])

  return {
    ...restSWR,
    data: response?.data,
    pagination
  }
}

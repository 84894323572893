import cepPromise from 'cep-promise'

import { type UseQueryRequestOptions, useQueryRequest } from '../service/use-query-request'

type CEP = {
  cep: string
  state: string
  city: string
  street: string
  neighborhood: string
}

async function fetchAddress(cep: string) {
  return cepPromise(cep)
}

export const useAddress = (cep: string, config?: UseQueryRequestOptions<CEP, unknown, true>) => {
  return useQueryRequest<CEP, unknown, true>(['useAddress', { cep }], () => fetchAddress(cep), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(cep) && cep.length === 9,
    ...config
  })
}

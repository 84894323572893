import { SpinnerIllustration } from '../illustrations/spinner.illustration'

import { WrapperLoader } from './styles'

interface LoaderProps {
  size?: number
  fill?: string
}

export const Loader = (props: LoaderProps) => {
  const { size, fill } = props

  return (
    <WrapperLoader data-testid="loader">
      <SpinnerIllustration fill={fill} width={size} height={size} />
    </WrapperLoader>
  )
}

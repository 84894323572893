import { type AxiosError, type AxiosPromise, type AxiosResponse } from 'axios'
import useSWR, { type ConfigInterface, type keyInterface, type responseInterface } from 'swr'
import { type SetRequired } from 'type-fest'

import { httpClientBanking } from '~/domains/banking/shared/infra/http-client-banking'
import { type CustomAxiosRequestConfig } from '~/domains/platform/infra/http/http-client-factory'

export type UseRequestStatus = 'refreshing' | 'resolved' | 'loading' | 'rejected' | 'idle'

export interface UseRequestResponse<Data, Error>
  extends Pick<responseInterface<AxiosResponse<Data>, AxiosError<Error>>, 'isValidating' | 'revalidate' | 'error'> {
  data: Data | undefined
  status: UseRequestStatus
  key: string
  mutate(response: AxiosResponse<Data>, revalidate?: boolean): void
  headers?: Record<string, any>
}

export interface UseRequestSWRConfig<Data = unknown, Error = unknown>
  extends Omit<ConfigInterface<AxiosResponse<Data>, AxiosError<Error>>, 'initialData'> {
  initialData?: Data
  customKey?: keyInterface
  notPerformFetchWhen?: boolean
}

export type UseRequestClientConfig = SetRequired<CustomAxiosRequestConfig, 'url'>

export function initialDataFactory<Data>(config: UseRequestClientConfig, initialData?: Data) {
  if (initialData) {
    return {
      status: 200,
      statusText: 'InitialData',
      config,
      headers: {},
      data: initialData
    }
  }
}

export function statusFactory(
  isValidating: boolean,
  // since this is just a boolean check, we can no coerce it
  data?: any,
  error?: any,
  isNotPerforming?: boolean
): UseRequestStatus {
  if (isNotPerforming) return 'idle'

  if (data) {
    if (isValidating) return 'refreshing'
    return 'resolved'
  }

  if (error) return 'rejected'

  if (isValidating) return 'loading'

  return 'idle'
}

export function configInitialDataDecorator<Data, Error>(
  swr: UseRequestSWRConfig<Data, Error>,
  request: UseRequestClientConfig
) {
  return {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
    ...swr,
    initialData: initialDataFactory(request, swr.initialData)
  }
}

function keyFactory(requestConfig: UseRequestClientConfig, customKey?: keyInterface) {
  return (
    customKey ||
    JSON.stringify({
      url: requestConfig.url,
      params: requestConfig.params,
      headers: requestConfig.headers,
      data: requestConfig.data
    })
  )
}

/**
 * @deprecated this method should not be used, use `useQueryRequest` instead
 * to see the difference between this and `useQueryRequest` see this [wiki page](https://github.com/dlpco/dashboard/wiki/useRequest-versus-useQueryRequest)
 */
export function useRequest<Data = Record<any, any>, Error = unknown>(
  requestConfig: UseRequestClientConfig,
  swrConfig: UseRequestSWRConfig<Data, Error> = {},
  client: <Data>(...args: any) => AxiosPromise<Data> = httpClientBanking
): UseRequestResponse<Data, Error> {
  const config = configInitialDataDecorator<Data, Error>(swrConfig, requestConfig)
  const key = keyFactory(requestConfig, swrConfig.customKey)
  const fetcher = () => {
    if (swrConfig.notPerformFetchWhen) {
      const solvedPromise = Promise.resolve() as unknown
      return solvedPromise as AxiosResponse<Data>
    }

    return client<Data>(requestConfig)
  }

  const {
    data: swrData,
    error,
    isValidating,
    revalidate,
    mutate
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(swrConfig.notPerformFetchWhen ? null : key, fetcher, config)

  const status = statusFactory(isValidating, swrData, error, swrConfig.notPerformFetchWhen)

  const values = {
    data: swrData?.data,
    headers: swrData?.headers,
    error,
    key: String(key),
    isValidating,
    status,
    revalidate,
    mutate
  }

  return values
}

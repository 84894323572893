import { type Subject } from '../../deus-ex-machina/types'
import { type ChatUser } from '../types'

export const chatUserFromSubject = (subject: Subject): ChatUser => {
  return {
    email: subject.email,
    name: subject.displayName,
    phone: subject.phoneNumber || '',
    loggedIn: true
  }
}

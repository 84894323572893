import { v4 as uuid } from 'uuid'

import { Bucket } from '~/domains/platform/lib/bucket'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'

export const getChatIdentity = () => {
  const storedIdentity = clientSideOnly(() => Bucket.local.get('twilio::chat::identity'))

  if (storedIdentity) return storedIdentity

  const newIdentity = uuid()

  clientSideOnly(() => Bucket.local.set('twilio::chat::identity', newIdentity))

  return newIdentity
}

import { useState } from 'react'
import { Stack } from '@dlpco/fluid-layout'
import { Heading, IconButton, Text } from '@dlpco/ginga-stone'

import { Box, Flex } from '~/domains/platform/design-system'
import { For } from '~/domains/platform/lib/utilities-components'

import { getChatIdentity } from '../helpers/get-chat-identity'
import { type ChatUser } from '../types'

import { CustomChooseClient } from './custom-chat-choose-client'
import { CustomChatDepartmentCard } from './custom-chat-department-card'
import { CustomLoggedChat } from './custom-logged-chat'

export function CustomChatClientHelp(props: { chatUser: ChatUser }) {
  const { chatUser } = props
  const [chat, hasChat] = useState(false)
  const [client, hasClient] = useState(false)
  const chatIdentity = getChatIdentity()

  const optionsToTalk = [
    {
      title: 'Falar por WhatsApp',
      action: () =>
        window.open('https://api.whatsapp.com/send?phone=+551130049680&text=Olá, você pode me ajudar?', '_blank')
    },
    { title: 'Falar por chat', action: () => hasChat(true) }
  ]

  if (chat) return <CustomLoggedChat chatUser={chatUser} chatIdentity={chatIdentity} />

  if (client) return <CustomChooseClient chatUser={chatUser} />

  return (
    <Box padding="1.25rem">
      <Stack space="1rem">
        <Flex alignItems="center" gap="1rem">
          <IconButton
            icon="direction-arrow-left-outline"
            size="small"
            variant="contentOnly"
            onClick={() => hasClient(true)}
          />
          <Heading weight="bold">Ajuda</Heading>
        </Flex>

        <Text>Estamos disponíveis 24h por dia, todos os dias da semana.</Text>

        <For
          of={optionsToTalk}
          render={item => <CustomChatDepartmentCard title={item.title} action={item.action} key={item.title} />}
        />
      </Stack>
    </Box>
  )
}

import { useEffect } from 'react'
import dynamic, { type LoaderComponent } from 'next/dynamic'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { logger } from '~/domains/platform/infra/monitoring/logger'
import { Bucket } from '~/domains/platform/lib/bucket'
import { clientSideOnly } from '~/domains/platform/lib/client-side-only'

import { type Subject } from '../../deus-ex-machina/types'

// Dynamic imports
const Twilio = dynamic(
  () =>
    // *** ATENTTION ****
    // Please, refere from trying to type this without casting.
    // This is casted due to ssr imports only, if I try to import typings to pass to dynamic
    // it will blow up on server side
    // You can find more info on this on this issue in next's github:
    // https://github.com/vercel/next.js/issues/4515
    import('./twilio').then(mod => mod.ChatTwilio).catch(error => logger(error)) as LoaderComponent<{
      subject?: Subject
    }>,
  { ssr: false }
)

interface ChatProps {
  subject?: Subject
}

export function ChatFlex(props: ChatProps) {
  const { subject } = props

  const hasTwilioEnabled = clientSideOnly(() => Heimdall.pass(['twilio_chat_support']))

  useEffect(() => {
    if (subject) {
      Bucket.local.set('public::chat', false)
    } else {
      Bucket.local.set('public::chat', true)
    }
  })

  if ((subject && hasTwilioEnabled) || !subject) return <Twilio subject={subject} />

  return null
}
